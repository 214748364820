import i18next from "i18next";
// import { useTranslation } from "react-i18next";
import { z, ZodSchema } from "zod";

const { language } = i18next;

export interface RegistrationData {
  firstName: string;
  lastName: string;
  company: string;
  businessAddress: string;
  businessAddress2?: string;
  city: string;
  state: string;
  zip: string;
  email?: string;
  password: string;
  confirmPassword: string;
}

const getRequiredErrorMessage = () => {
  return language === "en" ? "Required" : "Obligatoire";
};

export const RegistrationSchema: ZodSchema<RegistrationData> = z
  .object({
    firstName: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    lastName: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    company: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    businessAddress: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    businessAddress2: z.string().optional(),
    city: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    state: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    zip: z
      .string({ required_error: getRequiredErrorMessage() })
      .min(1, getRequiredErrorMessage()),
    email: z.string().email().optional(),
    password: z.string().min(6),
    confirmPassword: z.string().min(6),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export type RegistrationType = z.infer<typeof RegistrationSchema>;

export const ProfileSchema = z.object({
  firstName: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  lastName: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  company: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  businessAddress: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  businessAddress2: z.string().optional(),
  city: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  state: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  zip: z
    .string({ required_error: getRequiredErrorMessage() })
    .min(1, getRequiredErrorMessage()),
  email: z.string().email().optional(),
});

export type ProfileType = z.infer<typeof ProfileSchema>;
